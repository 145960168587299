import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    // handle external links
    document.addEventListener("click", (event) => {
      const tag = event.target.tagName.toLowerCase()
      const regexp = RegExp(window.location.host)

      if (tag == "a" && !regexp.test(event.target.href)) {
        event.preventDefault()
        window.open(event.target.href, "_blank")
      }
    })
  }

  updateCart(event) {
    event.preventDefault()
    const updateCart = new CustomEvent("updateCart")
    document.dispatchEvent(updateCart)
  }
}
