import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["header", "body", "flash"]

  initialize() {
    if (this.hasBodyTarget) {
      // set expanded state
      this.expanded = this.expanded

      // close expanded cart on clicks outside of cart
      document.addEventListener("click", (event) => {
        const bodySelector = `#${this.bodyTarget.id}`
        const headerSelector = `#${this.headerTarget.id}`

        if (this.expanded
          && !event.target.closest(bodySelector)
          && !event.target.closest(headerSelector)) {
          this.toggle(event)
        }
      })
    }
  }

  // update cart header and body contents
  async update(event) {
    const url = this.data.get("url")

    const response = await fetch(url)
    const json = await response.json()

    const timeout = window.setTimeout(() => {
      if (this.hasHeaderTarget) {
        this.headerTarget.innerHTML = json.html.header
      }

      if (this.hasBodyTarget) {
        this.bodyTarget.innerHTML = json.html.inventory
      }
    }, 150)
  }

  flash(event) {
    const [response, status, request] = event.detail
    const duration = 1200

    if (this.hasHeaderTarget) {
      const className = "header-cart--flashing"
      this.headerTarget.classList.add(className)

      const timeout = window.setTimeout(() => {
        this.headerTarget.classList.remove(className)
      }, duration)
    }

    if (response && "flash" in response && "cart" in response.flash) {
      const flash = document.createElement("div")
      flash.classList.add("cart-flash")
      flash.innerHTML = response.flash.cart
      this.element.appendChild(flash)

      this.flashTimeout = window.setTimeout(() => {
        flash.parentNode.removeChild(flash)
      }, duration)
    }
  }

  open(event) {
    event.preventDefault()
    this.expanded = true
  }

  toggle(event) {
    event.preventDefault()
    this.expanded = !this.expanded
  }

  set expanded(state) {
    const element = this.bodyTarget
    const expandedClass = "cart--expanded"

    if (state) {
      element.classList.add(expandedClass)
    } else {
      element.classList.remove(expandedClass)
    }

    this.data.set("expanded", state)
  }

  get expanded() {
    return this.data.get("expanded") == "true" ? true : false
  }
}
