import { Controller } from "stimulus"
import Headroom from "headroom.js"
import throttle from "lodash/throttle"

export default class extends Controller {

  static targets = ["title", "body", "toggleBtn"]

  initialize() {
    // init headroom to hide / show on scroll
    this.headroom = new Headroom(this.element, this.headroomOptions)
    this.headroom.init()

    // insert toggle button to header body

    const toggleBtnClass = "header-title-toggle-btn"
    if (!this.element.querySelector(`.${toggleBtnClass}`)) {
      const toggleHTML = `<div class="${toggleBtnClass}" data-action="click->header#toggle" target="header.toggleBtn">${"<span></span>".repeat(3)}</div>`
      this.titleTarget.insertAdjacentHTML("beforeend", toggleHTML)
    }

    // set expanded state / class
    this.expanded = this.expanded

    this.setBodyPadding()
  }

  // toggle expanded state
  toggle() {
    this.expanded = !this.expanded
  }

  // show header on cart update event
  updateCart() {
    if (!this.headroom.elem.classList.contains("headroom--pinned")
        && !this.headroom.elem.classList.contains("headroom--top")) {
      this.headroom.pin()
    }
  }

  // adapt the body padding to header height
  // on window resize events
  setBodyPadding() {
    const setPadding = () => {
      const height = this.element.getBoundingClientRect().height
      document.body.style.paddingTop = `${height}px`
    }

    // throttled resize listener
    window.addEventListener("resize", throttle(() => {
      setPadding()
    }, 500))

    setPadding()
  }

  // options for headrom scroll
  get headroomOptions() {
    return {
      offset: 30
    }
  }

  // change expanded state
  set expanded(bool) {
    this.data.set("expanded", bool)

    if (bool) {
      this.element.classList.add("header--expanded")
      this.headroom.freeze()
    } else {
      this.element.classList.remove("header--expanded")
      this.headroom.unfreeze()
    }
  }

  // get expanded state
  get expanded() {
    return this.data.get("expanded") == "true" ? true : false
  }
}
