import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    // Stripe is provided by Stripe.js
    const stripe = Stripe(this.publishableKey)

    this.element.addEventListener("click", (event) => {
      event.preventDefault()
      stripe.redirectToCheckout({
        sessionId: this.data.get("sessionId")
      }).then(result => {
        if (result.error) {
          this.errorCallback(result.error.message)
        }
      })
    })
  }

  errorCallback(message) {
    const html = `<div class="text--notification text--error">
      An error occured during your payment. Please repeat your <a href="${this.data.get('checkoutUrl')}">Checkout</a> or change your payment method<br><br>
      Error : ${message}</div>`
    this.element.insertAdjacentHTML("afterend", html)
    this.element.parentNode.removeChild(this.element)
  }

  get publishableKey() {
    return this.data.get("publishableKey")
  }
}
