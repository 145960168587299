import { Controller } from "stimulus"
// import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = ["dialogue", "deferred"]

  initialize() {
    // possible consent attributes
    this.consentCookies = ["consent_functional", "consent_payment"]

    // check if consents have been set
    this.fetchConsent().then(json => {
      const consentHash = json.consent
      let consentSet = false

      // check if consents have been set !== null
      Object.keys(consentHash).forEach(key => {
        consentSet = consentHash[key] === null ? false : true
      })


      // open dialogue when consent is unset
      // else set consent
      if (!consentSet) {
        document.body.insertAdjacentHTML("beforeend", json.html)
        window.dispatchEvent(new Event("resize"))

      } else {
        let consent = true
        Object.keys(consentHash).forEach(key => {
          consent = parseInt(consentHash[key], 10) === 1 ? true : false
        })

        // set consent
        this.consent = consent
      }
    })
  }

  // fetch consent
  // returns json.consent details
  // and json.html for consent dialogue
  async fetchConsent() {
    const response = await fetch(this.url)
    const json = await response.json()
    return json
  }

  // display consent dialogue
  openDialogue(event = false) {
    if (event) {
      event.preventDefault()
    }

    if (!this.hasDialogueTarget) {
      this.fetchConsent().then(json => {
        html = json.html
        document.body.insertAdjacentHTML("beforeend", html)
        window.dispatchEvent(new Event("resize"))
      })
    }
  }

  // close consent dialogue
  closeDialogue(event = false) {
    if (event) {
      event.preventDefault()
    }

    if (this.hasDialogueTarget) {
      const dialogue = this.dialogueTarget
      dialogue.parentNode.removeChild(dialogue)
    }
  }

  // accept or reject
  set(event) {
    event.preventDefault()

    // set consent to "accept" or "reject"
    // with data-consent attribute on event.target
    const consent = event.target.dataset.consent

    // build cookies object
    const cookies = {}
    this.consentCookies.forEach(cookie => {
      cookies[cookie] = consent == "accept" ? 1 : 0
    })

    // update cookies and close dialogue
    this.update(cookies).then(response => {
      if (response.ok) {
        this.consent = true
        this.closeDialogue()

        if (event.target.dataset.reload == "true") {
          location.reload()
        }
      }
    })
  }

  // accept external cookies
  accept(event) {
    event.preventDefault()
    const elem = event.target
    const attribute = elem.dataset.cookieAttribute

    console.log(attribute)
  }

  // decline external cookies
  decline(event) {
    event.preventDefault()
    this.update({ consent_external: 0 })
      .then(response => {
        if (response.ok) {
          this.consent = false
          this.closeDialogue()
        }
      })
  }

  // update cookies with json
  // cookies are stored in event.detail
  async update(cookies = {}) {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const body = JSON.stringify({ cookies: cookies })

    const response = await fetch(this.updateUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentiaks: "same-origin",
      body: body
    })

    return response
  }

  // load deferred scripts
  loadDeferred() {
    const deferreds = this.deferredTargets

    deferreds.forEach(deferred => {
      const tagName = deferred.tagName.toLowerCase()

      if (tagName == "script") {
        // create and insert script tag after deferred element
        const script = document.createElement("script")
        script.setAttribute("src", deferred.dataset.src)
        deferred.after(script)
      } else if (tagName == "template") {
        // insert template innerHTML after deferred element
        deferred.insertAdjacentHTML("afterend", deferred.innerHTML)
      }

      deferred.parentNode.removeChild(deferred)
    })
  }

  // url to fetch consent
  get url() {
    return this.data.get("url")
  }

  // url to update cookies
  get updateUrl() {
    return this.data.get("updateUrl")
  }

  // set consent and load deferred
  set consent(bool) {
    this.data.set("consent", bool)

    if (bool) {
      this.loadDeferred()
    }
  }

  get consent() {
    return this.data.get("consent") == "true" ? true : false
  }
}
