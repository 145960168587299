import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.video = this.element.querySelector("video")
    this.isPlaying = !this.video.paused
    this.isMuted = this.video.muted

    this.video.addEventListener("playing", (event) => {
      this.isPlaying = true
    })

    this.video.addEventListener("pause", (event) => {
      this.isPlaying = false
    })

    this.video.addEventListener("volumechange", (event) => {
      this.isMuted = this.video.muted
    })

    this.initControls()
  }

  initControls() {
    const muteBtn = document.createElement("div")
    muteBtn.classList.add("video-controller__mute-btn")
    muteBtn.dataset.action = "click->video#toggleMuted"
    this.element.appendChild(muteBtn)

    const playBtn = document.createElement("div")
    playBtn.classList.add("video-controller__play-btn")
    this.element.appendChild(playBtn)
  }

  togglePlaying() {
    if (this.video.paused) {
      this.video.play()
      this.video.muted = false
    } else {
      this.video.pause()
    }
  }

  set isPlaying(bool) {
    this.data.set("isPlaying", bool)
    const className = "video-controller--playing"

    if (bool && !this.element.classList.contains(className)) {
      this.element.classList.add(className)
    } else {
      this.element.classList.remove(className)
    }
  }

  get isPlaying() {
    return this.data.get("isPlaying") == "true" ? true : false
  }

  toggleMuted() {
    this.video.muted = !this.video.muted
  }

  set isMuted(bool) {
    this.data.set("isMuted", bool)
    const className = "video-controller--muted"

    if (bool && !this.element.classList.contains(className)) {
      this.element.classList.add(className)
    } else {
      this.element.classList.remove(className)
    }
  }

  get isMuted() {
    return this.data.get("isMuted") == "true" ? true : false
  }
}
