import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["select", "item"]

  initialize() {
    const select = this.selectTarget
    this.selected = select.value

    this.selectItem(this.selected)

    select.addEventListener("change", event => {
      const value = select.value

      this.selectItem(value)
      this.selected = value
    })
  }

  hide(elem) {
    elem.style.display = "none"
  }

  show(elem) {
    elem.style.display = ""
  }

  selectItem(value) {
    this.itemTargets.forEach(item => {
      const itemValue = item.dataset.selectValue

      if (itemValue == value) {
        item.style.display = ""
      } else {
        item.style.display = "none"
      }
    })
  }

  set selected(value) {
    this.data.set("selected", value)
  }

  get selected() {
    return this.data.get("selected")
  }
}
