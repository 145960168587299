import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = ["container"]

  // replace handler
  // replaces target (button, link, ...) with fetched content
  async replace(event) {
    event.preventDefault()
    const target= event.target
    const url = target.dataset.url ? target.dataset.url : target.href

    const spinner = this.spinner
    target.after(spinner)
    const container = target.closest(".loader-btn-container")
    target.parentNode.removeChild(target)

    const json = await this.fetchJSON(url)
    const element = container ? container : spinner
    element.insertAdjacentHTML("afterend", json.html)

    if (container) {
      container.parentNode.removeChild(container)
    } else {
      spinner.parentNode.removeChild(spinner)
    }
  }

  // reload handler
  // reloads inner html of containerTarget with fetched content
  async reload(event) {
    event.preventDefault()
    const target= event.target
    const url = target.dataset.url ? target.dataset.url : target.href

    const spinner = this.spinner
    this.containerTarget.innerHTML = ""
    this.containerTarget.append(spinner)

    const json = await this.fetchJSON(url)
    spinner.insertAdjacentHTML("afterend", json.html)

    spinner.parentNode.removeChild(spinner)
  }

  // on select handler for forms
  // submit form with selected value
  // replace inner of containerTarget with fetched content
  async select(event) {
    const select = event.target
    const form = select.closest("form")
    const method = form.method.toUpperCase()

    if (method == "GET") {
      // get with fetch from url and form query params
      const searchParams = new URLSearchParams()
      const formData = new FormData(form)

      for(let entry of formData.entries()) {
        searchParams.append(entry[0], entry[1])
      }

      const url = `${form.action}?${searchParams.toString()}`

      // fetch json with url, method and body
      const json = await this.fetchJSON(url, method)

      const containerTarget = this.containerTarget

      if (json && "html" in json && containerTarget) {
        containerTarget.innerHTML = ""
        containerTarget.appendChild(this.spinner)

        containerTarget.innerHTML = ""
        containerTarget.insertAdjacentHTML("afterbegin", json.html)
      }

    } else {
      // post form with Rails UJS
      Rails.fire(form, "submit")
    }
  }

  async fetchJSON(url, method = "get") {
    const csrfToken = document.querySelector("[name='csrf-token']").content
    const options = {
      method: method.toUpperCase(),
      headers: {
        "X-CSRF-Token": csrfToken,
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    }

    try {
      const response = await fetch(url, options)

      const json = await response.json()
      return json
    } catch(error) {
    }
  }

  get spinner() {
    const spinner = document.createElement("div")
    spinner.classList.add("loader-spinner")
    return spinner
  }

  get method() {
    const method = this.data.get("method")
    return method == null ? "replace" : method
  }
}
