import { Controller } from "stimulus"

export default class extends Controller {

  toggleFields(event) {
    const hidden = event.target.dataset.hidden == "true" ? true : false
    const fields = document.getElementById(event.target.dataset.id)
    const className = "form-fields--hidden"

    if (hidden) {
      fields.classList.remove(className)
      event.target.dataset.hidden = "false"
    } else {
      fields.classList.add(className)
      event.target.dataset.hidden = "true"
    }
  }
}
