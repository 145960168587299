import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.csrfToken = document.querySelector("[name='csrf-token']").content

    // render paypal buttons
    paypal.Buttons({
      style: {
        layout: "horizontal",
        color: "white",
        label: "pay",
        tagline: false
      },
      // create the order
      createOrder: () => {
        return fetch(this.data.get("createOrderUrl"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": this.csrfToken
          }
        }).then(response => {
          return response.json()
        }).then(data => {
          return data.id
        })
      },
      // order is approved, process completed order
      onApprove: (data, actions) => {
        // show processing placeholder
        this.processingCallback()

        // capture order and proceed
        actions.order.capture().then(details => {

          return fetch(this.data.get("completeUrl"), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": this.csrfToken
            },
            body: JSON.stringify({ order_id: data.orderID })
          }).then(response => {
            return response.json()
          }).then(json => {
            if (json.status.toLowerCase() == "completed") {
              this.paymentCompletedCallback(json)
            }
          })
        })
      },
      onError: (error) => {
        this.errorCallback(error)
      }
    }).render(`#${this.element.id}`)
  }

  processingCallback() {
    const html = `<div class="text--notification"><span class="text--ellipsis">
      Processing your payment</span></div>`
    this.element.style.display = "none"
    this.element.insertAdjacentHTML("afterend", html)
  }

  // payment completed redirect to receipt
  paymentCompletedCallback(json) {
    const url = json.redirect_url
    window.open(url, "_self")
  }

  // payment error dialogue
  errorCallback(error) {
    const html = `<div class="text--notification text--error">
      An error occured during your payment. Please repeat your <a href="${this.data.get('checkoutUrl')}">Checkout</a> or change your payment method<br><br>
      Error : ${error}</div>`
    this.element.insertAdjacentHTML("afterend", html)
    this.element.parentNode.removeChild(this.element)
  }

  // processing placeholder
  processingPlaceholder() {
    const elem = document.createElement("div")
    elem.classList.add("payment-processing")
    elem.innerHTML = `<span class="ellipsis">Processing PayPal payment. Please wait.</span>`
    return elem
  }
}
